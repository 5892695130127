<template>
  <ion-page id="visuals-page" class="page scrollable bg-transparent">
    <h1 class="title">Featured Images</h1>

    <div class="h-100 row visuals mt-3">
      <template v-for="(visual, index) in visuals" :key="index">
        <div class="col-sm-6 col-md-4 mb-3">
          <div class="d-flex justify-content-center one-visual">
            <img
              v-image
              class="visual-img clickable-item-hov"
              :src="resizeUpload(visual.visual_url)"
              @click.prevent="openVisualPreview(index)"
            />
          </div>
          <div class="text-center visual-text mt-2">
            From
            <router-link
              :to="{ name: 'character-profile-new', params: { slug: visual.character.slug } }"
              class="visual-text"
              ><strong>{{ get(visual, 'character.info.name', 'Unknown') }}</strong></router-link
            >
            by
            <router-link
              :to="{ name: 'profile', params: { username: get(visual, 'character.author.username', '') } }"
              class="visual-text"
              ><strong>{{ get(visual, 'character.author.username', 'Unknown') }}</strong></router-link
            >
          </div>
        </div>
      </template>
    </div>
    <div class="d-flex justify-content-center">
      <p v-if="nextpageExits" class="clickable-item-hov" @click="requestLoadMore">Load More</p>
    </div>

    <VisualPreviewModal
      :image-url="selectedVisualImg"
      :image-id="selectedVisualImgId"
      :is-open="openVisualPreviewModal"
      :caption="selectedVisualCaption"
      :source="selectedVisualSource"
      :all-images="visuals"
      :is-featured="true"
      :active-index="activeIndex"
      @loadmore="requestLoadMore"
      @updated="openVisualPreview"
      @dismiss-modal="closeVisualPreviewModal"
    />
  </ion-page>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Paging, Image, Character } from '@/shared/types/static-types';
import { getFeaturedVisuals } from '@/shared/actions/characters';
import VisualPreviewModal from '@/shared/modals/VisualPreviewModal.vue';
import { getNextPage } from '@/shared/helpers/pagination';
import { resizeUpload } from '@/shared/utils/upload';

@Options({
  name: 'FeaturedVisualsPage',
  components: { VisualPreviewModal },
})
export default class FeaturedVisualsPage extends Vue {
  get = get;
  resizeUpload = resizeUpload;
  public visuals: { visual_url: string; character: Character; image?: Image }[] = [];
  public paging: Paging | null = null;
  public selectedVisualImg = '';
  public selectedVisualImgId = '';
  public selectedVisualCaption = '';
  public selectedVisualSource = '';
  public openVisualPreviewModal = false;
  public activeIndex = 0;

  public async requestLoadMore() {
    const { results, ...paging } = await getNextPage(this.paging!);
    this.visuals = this.visuals.concat(results);
    this.paging = paging;
  }

  public get nextpageExits() {
    return !!this.paging?.next;
  }

  public getThumbnail(url: string) {
    return resizeUpload(url, '250x250');
  }

  public async fetchFeaturedVisuals(page = 1) {
    const { results, ...paging } = await getFeaturedVisuals('-created', page);
    this.visuals = this.visuals.concat(results);
    this.paging = paging;
  }

  public openVisualPreview(index: number) {
    const img = this.visuals[index].image;
    this.selectedVisualImg = this.visuals[index].visual_url;
    this.selectedVisualImgId = img ? img.id || '' : '';
    this.selectedVisualCaption = img ? img.description || '' : '';
    this.selectedVisualSource = img ? img.source || '' : '';
    this.openVisualPreviewModal = true;
    this.activeIndex = index;
  }

  public closeVisualPreviewModal() {
    this.openVisualPreviewModal = false;
    this.selectedVisualImg = '';
    this.selectedVisualImgId = '';
    this.selectedVisualCaption = '';
    this.selectedVisualSource = '';
  }

  mounted() {
    this.fetchFeaturedVisuals();
  }
}
</script>

<style lang="sass">
.page
  overflow-x: hidden !important
.visuals
  .one-visual
    img
      min-width: 180px
      width: 300px !important
      height: 350px !important
      border-radius: 10px
      border: solid gray 0.1px
      object-fit: cover
      @media(max-width: 575px)
        height: 400px !important
    .name
      font-size: 18px
      font-weight: bold

.visual-text
  font-size: 14px
  color: #214163
.dark
  .visual-text
    color: white !important
.title
  font-weight: bold
</style>
